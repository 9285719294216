<template>
  <div>
    <div id="ground-area" v-if="selectedDevice == 'desktop'">
      <GridLayout
        id="layout"
        ref="layout"
        :layout="layoutP"
        :is-resizable="false"
        :is-draggable="false"
        :col-num="idealGridRowHt"
        :row-height="50"
        :margin="[5, 5]"
        preserve-aspect-ratio
      >
        <GridItem
          v-for="item in layoutP"
          :minH="1.5"
          :minW="2"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :class="['container']"
        >
          <div class="container-box">
            <div
              :class="`element-list ${
                item.props && item.props.direction === 'vertical'
                  ? 'element-list-for-vertical'
                  : ''
              }`"
              v-if="item.elements"
            >
              <div
                v-for="(element, i) of item.elements"
                :key="i"
                :style="
                  getCSSAccordingToContainerDirection(
                    item.props && item.props.direction
                      ? item.props.direction
                      : '',
                    element
                  )
                "
                style="padding: 5px"
              >
                <div
                  :class="['d-flex flex-column align-center element']"
                  :style="
                    selectedType == 'real'
                      ? 'background-color: transparent;border:unset;'
                      : ''
                  "
                >
                  <div
                    id="structural"
                    class="parentDiv"
                    v-if="selectedType == 'structural'"
                  >
                    {{ element.name }}
                  </div>
                  <div
                    id="realData"
                    class="parentDiv"
                    v-if="selectedType == 'real'"
                  >
                    <template
                      v-if="
                        bindStyle[element.elemLinkTo] &&
                        bindStyle[element.elemLinkTo].visibility &&
                        bindStyle[element.elemLinkTo].componentFn
                      "
                    >
                      <div
                        class="elementrender"
                        style="width: 100%; height: 100%"
                      >
                        <component
                          :key="element.elemLinkTo + 'elementrender'"
                          :is="bindStyle[element.elemLinkTo].componentFn"
                          v-bind="
                            localVersionOfElementsData[element.elemLinkTo]
                          "
                        />
                      </div>
                    </template>
                    <template
                      v-else-if="
                        !bindStyle[element.elemLinkTo] ||
                        !bindStyle[element.elemLinkTo].visibility ||
                        !bindStyle[element.elemLinkTo].componentFn
                      "
                    >
                      <div class="noPreview">
                        <h4>
                          {{
                            (bindStyle[element.elemLinkTo] &&
                              bindStyle[element.elemLinkTo].message) ||
                            'No Preview ' + element.name
                          }}
                          <!-- ('No Preview ' +
                              localVersionOfElementsData[element.elemLinkTo] &&
                              localVersionOfElementsData[element.elemLinkTo]
                                .name) -->
                        </h4>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
      </GridLayout>
    </div>
    <template v-else>
      <div
        class="elseContainer"
        style="margin: auto 0; display: flex; justify-content: center"
      >
        <div
          class="phone"
          :class="!rotate ? 'view_2' : 'view_1'"
          id="phone_1"
          :style="`width: ${
            deviceScale.width ? deviceScale.width : 360
          }px; height: ${deviceScale.height ? deviceScale.height : 640}px;`"
        >
          <div class="camera" style="display: flex; justify-content: center">
            <span
              style="
                width: 10px;
                height: 10px;
                background-color: black;
                border-radius: 50%;
              "
            ></span>
          </div>
          <div class="contentKJH" style="overflow: auto">
            <DeviceTestPreview />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import {
  createNamespacedHelpers as cnh,
  mapActions as mapRootActions
} from 'vuex'

const { mapState: mapReportBuilderState } = cnh('ReportBuilder')
const { mapState, mapGetters } = cnh('PreviewStore')

export default {
  components: {
    GridLayout,
    GridItem,
    DeviceTestPreview: () => import('./DeviceTestPreview.vue')
  },
  computed: {
    ...mapReportBuilderState([
      'layout',
      'localVersionOfElementsData',
      'selectedPreviewLayout'
    ]),
    ...mapState(['structuralPreview', 'rotate']),
    ...mapGetters([
      'selectedType',
      'selectedDevice',
      'selectedDataType',
      'deviceScale'
    ]),
    idealGridRowHt() {
      let ht = (window.document.body.clientWidth * 2) / 3 / 12
      // console.log('window.d-b-width = ',window.document.body.clientWidth,' ht= ',ht)
      return ht
    }
  },
  data() {
    return {
      colNum: 12,
      bindStyle: {},
      layoutP: []
    }
  },
  created() {
    this.setLayoutP()
  },
  methods: {
    setLayoutP() {
      console.log(`[this.selectedPreviewLayout]: `, this.selectedPreviewLayout)
      if (!this.selectedPreviewLayout) {
        this.layoutP = this.layout
      } else if (!Array.isArray(this.selectedPreviewLayout)) {
        this.layoutP =
          (this.selectedPreviewLayout &&
            this.selectedPreviewLayout.structure &&
            this.selectedPreviewLayout.structure.containers) ||
          []
      } else {
        this.layoutP = this.selectedPreviewLayout
      }
      console.log(`[this.layoutP]: `, this.layoutP)
    },
    getCSSAccordingToContainerDirection(direction, element) {
      if (element) {
        // console.log('direction, element :>> ', direction, element);
        return direction === 'vertical'
          ? {
              'min-height': `calc(100% / ${this.colNum} * ${element.size}`,
              height: `calc(100% / ${this.colNum} * ${element.size}`
            }
          : {
              'min-width': `calc(100% / ${this.colNum} * ${element.size}`,
              width: `calc(100% / ${this.colNum} * ${element.size}`
            }
        // console.log('t :>> ', t);
        // return t
      }
    },
    isContainerDirectionIsVertical(container = {}) {
      return (
        container.props &&
        container.props.direction &&
        container.props.direction === 'vertical'
      )
    },
    async getBackground() {
      console.log('************************')
      for (let item of this.selectedPreviewLayout) {
        for (let elem of item.elements || []) {
          if (!this.bindStyle[elem.elemLinkTo]) {
            this.bindStyle[elem.elemLinkTo] = {}
          }
          const elemConf = this.localVersionOfElementsData[elem.elemLinkTo]
          this.bindStyle[elem.elemLinkTo]['visibility'] = false
          if (elemConf && elemConf.subType) {
            try {
              if (elemConf.configuration) {
                let comp = await this.previewComponent(elemConf)
                if (comp) {
                  this.bindStyle[elem.elemLinkTo].componentFn = comp
                  this.bindStyle[elem.elemLinkTo]['visibility'] = true
                }
              } else {
                this.bindStyle[elem.elemLinkTo]['message'] =
                  'No Configuration Provided'
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      }
      this.$forceUpdate()
      console.log(this.bindStyle, '0000000000000000000')
    },
    async previewComponent(selected) {
      try {
        let compPath = `../../elements-components/${selected.type.toLowerCase()}/${
          selected.subType
        }.vue`
        console.log('@@@@', compPath)
        if (selected.type == 'graph') {
          return () =>
            import(`../../elements-components/Graphs/ChartPreview.vue`)
        }
        await import(
          `../../elements-components/${selected.type.toLowerCase()}/${
            selected.subType
          }.vue`
        )
        return () =>
          import(
            `../../elements-components/${selected.type.toLowerCase()}/${
              selected.subType
            }.vue`
          )
      } catch (error) {
        console.error(error)
        return false
      }
    }
  },
  watch: {
    selectedPreviewLayout: {
      handler() {
        // this.bindStyle = {}
        this.setLayoutP()
        this.$forceUpdate()
      },
      deep: true
    },
    selectedType(nv) {
      console.log(nv)
      if (this.selectedType == 'real') {
        this.getBackground()
      }
    }
  }
}
</script>

<style scoped lang="scss">
$black-border: 1px solid grey;

#ground-area {
  max-width: 100%;
  max-height: 80vh; // previously it was 560px
  min-width: 900px;
  max-width: 1266px;
  min-height: 80vh; // previously it was 560px
  overflow-x: auto;
  margin-left: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.parentDiv {
  display: flex;
  // border: 1px solid blue;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container-box {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: calc(100% - 20px);
  position: absolute;
  left: 0;
  top: 0;
}

.element-list {
  display: flex;
  margin: 0.25rem;
  height: 100%;
  overflow: auto;
}
.element-list-for-vertical {
  flex-direction: column;
}
.element {
  height: 100%;
  background-color: $light-purple;
}

.container {
  // border: $silver;
  // background-color: #F2F7FF;
}

/* Table View */
.phone.view_1 {
  /* -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg); */
  transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
  /* box-shadow: -3px 3px 0 #000, -6px 6px 0 #000, -9px 9px 0 #000,
    -12px 12px 0 #000, -14px 10px 20px #000; */
}
/*  Front View */
.phone.view_2 {
  /* -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); */
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  box-shadow: 0px 3px 0 #000, 0px 4px 0 #000, 0px 5px 0 #000, 0px 7px 0 #000,
    0px 10px 20px #000;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg);
  }
  50% {
    -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-40deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg);
  }
}

.phone {
  border: 7px solid #121212;
  border-width: 14px 7px;
  border-radius: 40px;
  //
  margin: 50px auto;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

/* Rotate Animation */

.view_1.rotate {
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@media (max-width: 900px) {
  #wrapper {
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
}

@media (max-width: 700px) {
  #wrapper {
    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
  }
  .phone {
    margin: 0 0 0 -70px;
  }
}

@media (max-width: 500px) {
  #wrapper {
    -webkit-transform: scale(0.4, 0.4);
    transform: scale(0.4, 0.4);
  }
}

/* Fade In Animation */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.display-area {
  transform: rotateX(3deg) rotateY(0deg) rotateZ(0deg);
}

// /*Basic Phone styling*/

// .phone {
//   border: 40px solid #121212;
//   border-width: 55px 7px;
//   border-radius: 40px;
//   // margin: 50px auto;
//   overflow: hidden;
//   -webkit-transition: all 0.5s ease;
//   transition: all 0.5s ease;
//   -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
//   -moz-animation: fadein 2s; /* Firefox < 16 */
//   -ms-animation: fadein 2s; /* Internet Explorer */
//   -o-animation: fadein 2s; /* Opera < 12.1 */
//   animation: fadein 2s;
// }

// /*Different Perspectives*/

// /* Table View */
// .phone.view_1 {
//   -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg);
//   transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
//   box-shadow: -3px 3px 0 #000, -6px 6px 0 #000, -9px 9px 0 #000,
//     -12px 12px 0 #000, -14px 10px 20px #000;
// }
// /*  Front View */
// .phone.view_2 {
//   -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
//   transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
//   box-shadow: 0px 3px 0 #000, 0px 4px 0 #000, 0px 5px 0 #000, 0px 7px 0 #000,
//     0px 10px 20px #000;
// }

// @-webkit-keyframes rotate {
//   0% {
//     -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg);
//   }
//   50% {
//     -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-40deg);
//   }
//   100% {
//     -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg);
//   }
// }

// /* Rotate Animation */

// .view_1.rotate {
//   -webkit-animation-name: rotate;
//   -webkit-animation-duration: 15s;
//   -webkit-animation-iteration-count: infinite;
//   -webkit-animation-timing-function: linear;
// }

// /*Controls*/

// #controls {
//   position: absolute;
//   top: 80px;
//   left: 20px;
//   font-size: 0.9em;
//   color: #333;
//   width: 17px;
// }

// #controls div {
//   margin: 10px;
// }

// #controls div label {
//   width: 150px;
//   display: block;
//   float: left;
//   color: #fff;
// }

// #phone-controls {
//   position: absolute;
//   top: 80px;
//   right: 20px;
//   width: 200px;
//   font-size: 14px;
// }

// #phones {
//   border-top: 1px solid #fff;
//   margin-top: 20px;
//   padding-top: 20px;
// }

// #phones button {
//   outline: none;
//   width: 198px;
//   border: 1px solid #016aa0;
//   border-radius: 5px;
//   -moz-border-radius: 5px;
//   -webkit-border-radius: 5px;
//   -o-border-radius: 5px;
//   background-color: #016aa0;
//   height: 40px;
//   margin: 10px 0;
//   color: #fff;
//   -webkit-transition: all 0.2s;
//   transition: all 0.2s;
// }

// #phones button:hover {
//   color: #444;
//   background-color: #eee;
// }

// #views button {
//   outline: none;
//   width: 198px;
//   border: 1px solid #00a8ff;
//   border-radius: 5px;
//   -moz-border-radius: 5px;
//   -webkit-border-radius: 5px;
//   -o-border-radius: 5px;
//   background-color: #00a8ff;
//   height: 40px;
//   margin: 10px 0;
//   color: #fff;
//   -webkit-transition: all 0.2s;
//   transition: all 0.2s;
// }

// #views button:hover {
//   color: #444;
//   background-color: #eee;
// }

// @media (max-width: 900px) {
//   #wrapper {
//     -webkit-transform: scale(0.8, 0.8);
//     transform: scale(0.8, 0.8);
//   }
// }

// @media (max-width: 700px) {
//   #wrapper {
//     -webkit-transform: scale(0.6, 0.6);
//     transform: scale(0.6, 0.6);
//   }
//   .phone {
//     margin: 0 0 0 -70px;
//   }
// }

// @media (max-width: 500px) {
//   #wrapper {
//     -webkit-transform: scale(0.4, 0.4);
//     transform: scale(0.4, 0.4);
//   }
// }

// /* Fade In Animation */

// @keyframes fadein {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// /* Firefox < 16 */
// @-moz-keyframes fadein {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// /* Safari, Chrome and Opera > 12.1 */
// @-webkit-keyframes fadein {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// /* Internet Explorer */
// @-ms-keyframes fadein {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// /* Opera < 12.1 */
// @-o-keyframes fadein {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
</style>
